<template>
  <div class="info">
    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="姓名">
            <a-input
              v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="性别">
            <a-radio-group
              v-decorator="[
                  'sex',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
            >
              <a-radio
                v-for="item in [{
                name: '男',
                value: 'Male'
              }, {
                name: '女',
                value: 'FeMale'
              }]"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="出生日期">
            <a-date-picker
              :inputReadOnly="true"
              style="width: 100%"
              placeholder
              v-decorator="[
                  'birthday',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="身份证号">
            <a-input
              v-decorator="[
                  'idCard',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="国籍">
            <a-input
              v-decorator="[
                  'nationality',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="籍贯">
            <a-input
              v-decorator="[
                  'nativePlace',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="民族">
            <a-input
              v-decorator="[
                  'ethnicGroup',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="身高(cm)">
            <a-input-number
              style="width: 100%"
              v-decorator="[
                  'height',
                ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="体重(kg)">
            <a-input-number
              style="width: 100%"
              v-decorator="[
                  'weight',
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="兴趣爱好">
            <a-input v-decorator="[
                  'interests',
                ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="专业特长">
            <a-input v-decorator="[
                  'expertises',
                ]" />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- <CardHeader :icon="icon4" title="联系信息" /> -->

      <a-row>
        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="手机号">
            <a-input
              v-decorator="[
                  'mobile',
                  {
  
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="个人邮箱">
            <a-input
              v-decorator="[
                  'emailIndividual',
               {
  
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="通讯地址">
            <a-input
              v-decorator="[
                  'address',
       {
  
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="紧急联系人">
            <a-input
              v-decorator="[
                  'emergencyContact',
                  {
  
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="12" :sm="24">
          <a-form-item label="紧急联系人电话">
            <a-input
              v-decorator="[
                  'emergencyContactMobile',
                  {
  
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-button :loading="saveLoading" htmlType="submit" type="primary">保存</a-button>
      </div>
    </a-form>
  </div>
</template>
  
  <script>
// import CardHeader from "./components/card-header.vue";

function add(data) {
  return fetch("/api/user-service/employee/saveBasicInfo", {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  components: {
    // CardHeader
  },

  data() {
    return {
      form: this.$form.createForm(this),

      // icon1: require("@/assets/employee/icon1.png"),
      // icon4: require("@/assets/employee/icon4.png"),

      saveLoading: false,

      url: "http://10.23.61.200:8989/"
    };
  },

  mounted() {
    const id = window.localStorage.getItem("register-id");
    if (id) {
      const that = this;
      this.$confirm({
        title: "您已注册成功，是否去录入人脸？",
        onOk() {
          window.location.href = `${that.url}?id=${id}`;
        }
      });
    }
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          if (values.idCard.length !== 18) {
            this.$message.error("请输入正确的身份证号");
            return;
          }

          const that = this;
          this.$confirm({
            title: "请确认信息无误！是否继续提交？",
            onOk() {
              that.saveLoading = true;

              add({
                ...values,
                birthday: values.birthday.format("YYYY-MM-DD"), // 出生日期
                status: 1
              })
                .then(res => {
                  if (res.status !== 201) {
                    return res.json();
                  } else {
                    return res.text();
                  }
                })
                .then(res => {
                  console.log("res", res);
                  if (typeof res === "object" && res) {
                    alert(res.message);
                  } else if (typeof res === "string" && res) {
                    window.localStorage.setItem("register-id", res);
                    window.location.href = `${that.url}?id=${res}&name=${values.name}&mobile=${values.mobile}`;
                  } else {
                    alert("数据格式不对");
                  }
                })
                .finally(() => {
                  that.saveLoading = false;
                });
            }
          });
        } else {
          alert("请填写必填项");
        }
      });
    }
  }
};
</script>
  
  <style lang="less" scoped>
.info {
  padding: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    height: 120px;
    margin-right: 30px;
  }
  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.center {
  margin-top: 80px;
  padding-bottom: 80px;
}

.tips {
  text-align: center;
  color: #999;
  margin-top: 16px;
}

@media screen and (max-width: 720px) {
  .header {
    flex-direction: column;

    .logo {
      height: 80px;
      margin-right: 0;
    }
    .title {
      font-size: 18px;
      font-weight: 400;
      line-height: 38px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
    }
  }
}
</style>